import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Container,
  Stack,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { FILES } from 'src/constants/url';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import DeleteItem from './DeleteItem';
// import CreateItem from './CreateItem';
// import UpdateItems from './UpdateItems';
import Label from 'src/components/label/Label';

const FilesPage = () => {
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('permissions');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const FetchFiles = async () => {
    setLoading(true);
    if (token != null) {
      await axios
        .get(FILES, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,

          },
        })
        .then((response) => {
          if (response.status === 200) {
            setFiles(response.data);
        } else if (response.status === 401) {
            navigate('/', { replace: true });
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      navigate('/login');
    }
    setLoading(false);
  };
  useEffect(() => {
    FetchFiles();
  }, [reload]);
  return (
    <>
      <Helmet>
        <title> Backup Files | Digi Dine </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Files
          </Typography>
          {/* {roles.includes('create-Item') && <CreateItem setReload={setReload} reload={reload} />} */}
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            {files.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  '& .MuiPaper-root ': {
                    overflowX: 'scroll',
                  },
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                        <TableCell align="center">File Name</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">{file.name}</TableCell>
                        <TableCell align="center">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<VisibilityIcon />}
                            href={file.url}
                            target="_blank"
                        >
                            Download
                        </Button>
                        {/* Implement delete functionality if needed */}
                        </TableCell>
                    </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                {' '}
                No items yet{' '}
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default FilesPage;
