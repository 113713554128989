import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Container,
  Stack,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { TABLE } from 'src/constants/url';
import DeleteTable from './DeleteTable';
import CreateTable from './CreateTable';
import UpdateTable from './UpdateTable';
import Label from 'src/components/label/Label';
const TablesPage = () => {
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('permissions');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const FetchTables = async () => {
    setLoading(true);
    if (token != null) {
      await axios
        .get(TABLE, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setInfo(response.data.data);
          } else if (response.status === 401) {
            navigate('/', { replace: true });
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      navigate('/login');
    }
    setLoading(false);
  };
  useEffect(() => {
    FetchTables();
  }, [reload]);
  return (
    <>
      <Helmet>
        <title> Tables | Digi Dine </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tables
          </Typography>
          {roles.includes('create-Table') && <CreateTable reload={reload} setReload={setReload} />}
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            {info.length > 0 ? (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell align="center">Table-id</TableCell> */}
                        <TableCell align="center">Table Number</TableCell>
                        <TableCell align="center">Table Status</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {info.map((item) => (
                        <TableRow key={item.id} id={item.id}>
                          {/* <TableCell align="center">{item.id}</TableCell> */}
                          <TableCell align="center">{item.table_number}</TableCell>
                          <TableCell align="center">
                            {item.available == 0 ? (
                              <Label
                                variant="ghost"
                                sx={{
                                  color: 'red',
                                }}
                              >
                                Reserved
                              </Label>
                            ) : (
                              <Label
                                variant="ghost"
                                sx={{
                                  color: 'green',
                                }}
                              >
                                Unreserved
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {/* <UpdateTable id={item.id} reload={reload} setReload={setReload} i={item} /> */}
                            {roles.includes('delete-Table') && (
                              <DeleteTable id={item.id} reload={reload} setReload={setReload} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                {' '}
                No Tables yet{' '}
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default TablesPage;
