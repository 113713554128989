import { useNavigate } from 'react-router-dom';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
  Snackbar,
} from '@mui/material';
import Iconify from '../../../components/iconify';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { LOGIN, refreshToken } from '../../../constants/url';
import { requestForToken } from 'src/firebase';

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmitLogin = async () => {
    setLoading(true);
    const data = {
      email: formik.values.email,
      password: formik.values.password,
    };
    await axios
      .post(LOGIN, data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      .then((response) => {
        if (response.status === 200) {
          requestForToken(response.data.data.authorization.token);
          localStorage.setItem('token', response.data.data.authorization.token);
          localStorage.setItem('userName', response.data.data.user.name);
          localStorage.setItem('uesrEmail', response.data.data.user.email);
          localStorage.setItem('role_name', response.data.data.user.role_name);
          localStorage.setItem('permissions', response.data.data.permissions);
          response.data.data.user.role_name === "SuperAdmin"
            ? navigate('/dashboard/app', { replace: true })
            :
            navigate('/dashboard/orders', { replace: true })

          setOpen(true);
          setMessage(false);
          // setTimeout(() => {
          //   window.location.reload()
          // }, 3000)
        }
      })

      .catch((error) => {
        setLoading(false);
        setMessage(true);
        showErrorAlert(error.response.data.message);


        console.log(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Enter your Email'),
    password: Yup.string().required('Enter your password'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmitLogin();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Typography variant="h3" className="text-center mb-4">
          Sign in
        </Typography>
        {message ? (
          <Alert severity="error" className="mb-4 text-start">
            <AlertTitle>Error</AlertTitle>
            <div>
              {' '}
              <strong>{erroralert}</strong>{' '}
              
            </div>
          </Alert>
        ) : (
          ''
        )}
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email"
            variant="filled"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            name="password"
            variant="filled"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {loading ? (
          <LoadingButton fullWidth size="large" type="submit" variant="contained" className="btn-bg my-4 ">
            <CircularProgress style={{ color: '#fff' }} size={30} />
          </LoadingButton>
        ) : (
          <LoadingButton fullWidth size="large" type="submit" variant="contained" className="btn-bg my-4" style={{ backgroundColor: '#FAA61A' }}>
            Login
          </LoadingButton>
        )}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className="ic">
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
            style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
          >
            success! Logged in successfully
          </Alert>
        </Snackbar>
      </Form>
    </FormikProvider>
  );
}
