import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImageUploading from 'react-images-uploading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CategorySection from '../CategorySection';
import { ADD_LANG, ITEMS } from 'src/constants/url';
const CreateItem = ({ reload, setReload }) => {
  const [all, setAll] = useState([]);
  const [loadingLang, setLoadingLang] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [nameEn, setNameEn] = useState('');
  const [nameAr, setNameAr] = useState('');
  const [nameKu, setNameKu] = useState('');
  const [descEn, setDescEn] = useState('');
  const [descAr, setDescAr] = useState('');
  const [descKu, setDescKu] = useState('');
  const [price, setPrice] = useState('');
  const [offer_price, setOfferPrice] = useState('');
  const [weight, setWeight] = useState('');
  const [trending, setTrending] = useState(0);
  const [vegan, setVegan] = useState(0);
  const [lactose, setLactose] = useState(0);
  const [gluten, setGluten] = useState(0);
  const [images, setImages] = useState([]);
  const [category_section, setcategory_section] = useState('');
  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState('');
  const [erroralert, showErrorAlert] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const FetchAllLang = async () => {
    setLoadingLang(true)
    await axios
      .get(ADD_LANG, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAll(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoadingLang(false)
  };
  useEffect(() => {
    FetchAllLang();

  }, []);
  const handleChangeInputs = (e, id) => {
    const { value } = e.target;
    setAll((inputs) =>
      inputs?.map((list, index) =>
        list.id === id ? { ...list, inputs: value } : list,
      )
    );
  };
  const handleChangeDesc = (e, id) => {
    const { value } = e.target;
    setAll((desc) =>
      desc?.map((list, index) =>
        list.id === id ? { ...list, desc: value } : list,
      )
    );
  };
  const CreateItemForm = () => {
    setLoading(true);
    const formData = new FormData();
    all.map((item) => formData.append(`name[${item.code}]`, [item.inputs]));
    all.map((i) => formData.append(`description[${i.code}]`, [i.desc]));
    formData.append('price', price);
    formData.append('is_trending', trending);
    formData.append('vegan', vegan);
    formData.append('lactose_free', lactose);
    formData.append('gluten_free', gluten);
    if (offer_price != null) {
      formData.append('offer_price', offer_price);
    }
    formData.append('weight', weight);
    {
      category_section != null ? formData.append('category_id', category_section) : '';
    }
    if (images.length != 0) {
      formData.append('image', images[0].file);
    }
    axios
      .post(ITEMS, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
      })

      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
        setEmpty('please add category then try to add item');
      })
      .finally(() => {
        setLoading(false);
        setNameEn('');
        setNameAr('');
        setNameKu('');
        setDescEn('');
        setDescAr('');
        setDescKu('');
        setPrice('');
        setOfferPrice('');
        setWeight('');
        setTrending(0);
        setVegan(0);
        setLactose(0);
        setGluten(0);
        setImages([]);
        setcategory_section('');
      });
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        <AddIcon /> New Item
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Item</DialogTitle>

        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
                {category_section != null ? '' : empty}
              </Alert>
            ) : (
              ''
            )}
            <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="upload__image-wrapper position-relative mb-3">
                  <button
                    className="btn-add w-100 d-block text-white border-0 py-2"
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    upload Image
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          onClick={() => onImageRemove(index)}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
            {loadingLang ? <Skeleton animation="wave" width="100%" /> :
              <>
                {all.length > 0 && all.map((item) => (
                  <TextField
                    id="filled-basic"
                    label={`Item Name ${item.title}`}
                    value={item.inputs}
                    key={item.code}
                    variant="filled"
                    fullWidth
                    onChange={(e) => handleChangeInputs(e, item.id)}
                    className="mb-2"
                  />
                ))}
              </>
            }
            {loadingLang ? <Skeleton animation="wave" width="100%" /> :
              <>
                {all.length > 0 && all.map((i) => (
                  <TextField
                    id="filled-multiline-static"
                    label={`Item Description ${i.title}`}
                    value={i.desc}
                    key={i.code}
                    multiline
                    rows={4}
                    variant="filled"
                    fullWidth
                    onChange={(e) => handleChangeDesc(e, i.id)}
                    className="mb-2"
                  />
                ))}
              </>
            }
            <TextField
              id="filled-basic"
              label="Item Price"
              value={price}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setPrice(e.target.value)}
              className="mb-2"
            />

            <TextField
              id="filled-basic"
              label="Item Offer Price"
              value={offer_price}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setOfferPrice(e.target.value)}
              className="mb-2"
            />

            <TextField
              id="filled-basic"
              label="Item Weight"
              value={weight}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setWeight(e.target.value)}
              className="mb-2"
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={trending === 1 ? true : false}
                onChange={(e) => setTrending(e.target.checked ? 1 : 0)}
                label=" Trending"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={vegan === 1 ? true : false}
                onChange={(e) => setVegan(e.target.checked ? 1 : 0)}
                label="vegan"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={lactose === 1 ? true : false}
                onChange={(e) => setLactose(e.target.checked ? 1 : 0)}
                label="lactose free"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={gluten === 1 ? true : false}
                onChange={(e) => setGluten(e.target.checked ? 1 : 0)}
                label="gluten free"
              />
            </FormGroup>
            <CategorySection category_section={category_section} setcategory_section={setcategory_section} />
          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={CreateItemForm}>
              Save
            </Button>
          )}

          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          success! Category Added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateItem;
